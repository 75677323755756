import Container from "../components/container";
import BlockContent from "../components/block-content";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import React from "react";
import SEO from "../components/seo";
import { graphql } from "gatsby";

export const query = graphql`
  query AboutQuery {
    site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
      _rawCv(resolveReferences: { maxDepth: 5 })
    }
  }
`;

const About = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const about = data && data.site;

  return (
    <Layout>
      <SEO title="About" />
      <Container>
        <h1>About</h1>
        {about && <BlockContent blocks={about._rawCv || []} />}
      </Container>
    </Layout>
  );
};

export default About;
